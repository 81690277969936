import Layout from '@layouts';
import * as React from 'react';
import { FC } from 'react';

type CookiePolicyPageProps = {};

const CookiePolicyPage: FC<CookiePolicyPageProps> = () => {
  return (
    <Layout url="/cookie-policy/" title="Cookie Policy">
      <h1 className="text-3xl lg:text-4xl font-emp text-center mt-20 lg:mt-30 mb-8">
        Cookie Policy
      </h1>
      <h2>Why do we use cookies?</h2>
      <p>
        We use cookies to learn how you interact with our content and to improve
        your experience when visiting our website(s). For example, some cookies
        remember your language or preferences so that you do not have to
        repeatedly make these choices when you visit one of our websites. We
        also use cookies to help us with geolocation tracking. Additionally,
        cookies allow us to serve you specific content, such as videos on our
        website(s). We may employ the learnings of your behavior on our
        website(s) to serve you with targeted advertisements on third-party
        website(s) in an effort to “re-market” our products and services to you.
      </p>
      <p>What types of cookies do we use?</p>
      <h3>First-Party and Third-Party Cookies</h3>
      <p>We use both first-party and third-party cookies on our website.</p>
      <p>
        First-party cookies are cookies issued from the Oilynx.com domain that
        are generally used to identify language and location preferences or
        render basic site functionality.
      </p>
      <p>
        Third-party cookies belong to and are managed by other parties, such as
        Redsleeve SA business partners or service providers.
      </p>
      <h3>Session Cookies</h3>
      <p>
        Session cookies are temporary cookies that are used to remember you
        during the course of your visit to the website, and they expire when you
        close the web browser.
      </p>
      <h3>Persistent Cookies</h3>
      <p>
        Persistent cookies are used to remember your preferences within the
        website and remain on your desktop or mobile device even after you close
        your browser or restart your computer. We use these cookies to analyse
        user behavior to establish visit patterns so that we can improve our
        website functionality for you and others who visit our website(s). These
        cookies also allow us to serve you with targeted advertising and measure
        the effectiveness of our site functionality and advertising.
      </p>
      <h2>How are cookies used for advertising purposes?</h2>
      <p>
        Cookies and ad technology such as web beacons, pixels, and anonymous ad
        network tags help us serve relevant ads to you more effectively. They
        also help us collect aggregated audit data, research, and performance
        reporting for advertisers. Pixels enable us to understand and improve
        the delivery of ads to you, and know when certain ads have been shown to
        you. Since your web browser may request advertisements and web beacons
        directly from ad network servers, these networks can view, edit, or set
        their own cookies, just as if you had requested a web page from their
        site.
      </p>
      <p>
        Although we do not use cookies to create a profile of your browsing
        behavior on third-party sites, we do use aggregate data from third
        parties to show you relevant, interest-based advertising. We do not
        provide any personal information that we collect to advertisers. You can
        opt out of off-site and third-party-informed advertising by adjusting
        your cookie settings. Opting out will not remove advertising from the
        pages you visit, but, instead, opting out will result in the ads you see
        not being matched to your interests. This implies that the ad(s) you see
        will not be matched to your interests by those specific cookies.
      </p>
      <h2>How are third party cookies used?</h2>
      <p>
        For some of the functions within our websites we use third party
        suppliers, for example, when you visit a page with videos embedded from
        or links to YouTube. These videos or links (and any other content from
        third party suppliers) may contain third party cookies, and we encourage
        you to consult the privacy policies of these third party vendors on
        their websites for information regarding their use of cookies.
      </p>
      <h2>How do I reject and delete cookies?</h2>
      <p>
        You can choose to reject or block all or specific types of cookies set
        by virtue of your visit to the Oilynx website by clicking on the cookie
        preferences on our website(s). You can change your preferences for the
        Oilynx Platform and websites and/or the websites of any third party
        suppliers by changing your browser settings. Please note that most
        browsers automatically accept cookies. Therefore, if you do not wish
        cookies to be used, you may need to actively delete or block the
        cookies. If you reject the use of cookies, you will still be able to
        visit our websites but some of the functions may not work correctly. You
        may also visit{' '}
        <a href="https://www.allaboutcookies.org" target="_blank">
          www.allaboutcookies.org
        </a>
        for details on how to delete or reject cookies and for further
        information on cookies generally. By using our website without deleting
        or rejecting some or all cookies, you agree that we can place those
        cookies that you have not deleted or rejected on your device.
      </p>
    </Layout>
  );
};

export default CookiePolicyPage;
